export default {
	data: () => ({
		langRo: {
			btnPath: {
				home: 'Acasa',
				category: 'Cursuri',
			},
			skillTitle: {
				text: 'Beneficiile ',
				color: 'cursului'
			},
			courseTitle: {
				text: 'Conținutul',
				color: ' cursului'
			},
			courseDesc: 'Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
			chapters: 'teoretic',
			modules: 'videoclipuri practice',
			priceTitle: 'Costul cursului',
			buy: 'Cumpără curs',
			subscription: 'Magazin online',
			login: 'Înscrie-te acum',
			SeeTheProgram: 'Vezi programul',
			modalBuyCourse: {
				title: 'Cumpără curs',
				name: 'Numele',
				phone: 'Telefon',
				modeBuy: 'Modalități de plată',
				modalBtnBuy: 'Cumpără',
				buy: 'Card bancar',
				conditions: 'Accept termenile si conditiile',
				terms: 'Reguli de utilizare'
			}
		}
	})
}








