export default {
	data: () => ({
		langRu: {
			btnPath: {
				home: 'Домой',
				category: 'Курсы',
			},
			skillTitle: {
				text: 'Преимущества ',
				color: 'курса'
			},
			courseTitle: {
				text: 'Содержание',
				color: ' курса'
			},
			courseDesc: 'Вас ждут онлайн лекции, практические задания, воркшопы и мастерклассы',
			chapters: 'теоретическое',
			modules: 'практических видео',
			priceTitle: 'Стоимость курса',
			buy: 'Купить курс',
			subscription: 'Онлайн магазин',
			login: 'Войти Сейчас',
			SeeTheProgram: 'Посмотреть программу',
			modalBuyCourse: {
				title: 'Купить курс',
				name: 'Имя',
				phone: 'Телефон',
				modeBuy: 'Вариант оплаты',
				modalBtnBuy: 'перейти на оплату',
				buy: 'Банковская карта',
				conditions: 'Cогласен с условиями и сроками',
				terms: 'Правила пользования'
			}
			
		}
	})
}