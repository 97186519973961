export default {
	data: () => ({
		langEng: {
			btnPath: {
				home: 'Home',
				category: 'Courses',
			},
			skillTitle: {
				text: 'Аdvantages of ',
				color: 'the course'
			},
			courseTitle: {
				text: 'Course',
				color: ' content'
			},
			courseDesc: 'Online lectures, practical assignments, workshops and master classes are waiting for you',
			chapters: 'theoretical',
			modules: 'practical videos',
			priceTitle: 'Price',
			buy: 'Buy a course',
			subscription: 'Online magazine',
			login: 'Sign up now',
			SeeTheProgram: 'See the program',
			modalBuyCourse: {
				title: 'Buy course',
				name: 'Name',
				phone: 'Phone',
				modeBuy: 'Pay mode',
				modalBtnBuy: 'Buy',
				buy: 'Card bancar',
				conditions: 'Accept term and conditions',
				terms: 'Terms of use'
			}
		}
	})
}


