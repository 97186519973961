<template>
<div class="course__content__wrp">
  <div class="course__content">
    <div class="fig">
      <svg width="70" height="50" viewBox="0 0 70 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M70 0H0L69.9996 50L70 0Z" fill="white"/>
        <path d="M0 50H70L0.000427246 0L0 50Z" fill="#000001"/>
      </svg>
    </div>
    <div class="top">
      <h2>Conținutul <b>cursului</b></h2>
      <div class="right">
        <div class="dsc">Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri</div>
        <div class="btm">
          <div class="card">
            <div class="num">
              {{ cards.length }}
            </div>
            <span>capitole</span>
          </div>
          <div class="card">
            <div class="num">
              16
            </div>
            <span>module tematice</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content__col">
      <div class="card" @click="card.status = !card.status" :class="{'active': card.status}" v-for="card in cards" :key="card.id">
        <div class="title">
          <strong>{{ card.name }}</strong>
          <div class="btn"></div>
        </div>
        <p>{{ card.description }}</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props:['content'],
  data:()=>({
    cards: [
      {
        name: 'Anatomia și fiziologia pielii și părului.',
        description: 'Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
        status: true
      },
      {
        name: 'Igiena. Notiune de asepsie si antisepsie.',
        description: 'Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
        status: false
      },
      {
        name: 'Bolile dermatologice ale pielii. Tactica cosmeticianului.',
        description: 'Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
        status: false
      },
      {
        name: 'Programe pentru ingrijirea tenului gras, indicatii, tehnici, protocoale.',
        description: 'Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
        status: false
      },
      {
        name: 'Programe pentru ingrijirea tenului mixt, indicatii , tehnici, protocoale.',
        description: 'Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
        status: false
      },
      {
        name: 'Programe pentru ingrijirea tenului normal, indicatii, tehnici, protocoale.',
        description: 'Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
        status: false
      },
      {
        name: 'Programe pentru ingrijirea tenului uscat, indicatii ,tehnici, protocoale.',
        description: 'Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
        status: false
      },
      {
        name: 'Programe pentru ingrijirea tenului imbatrinit, indicatii, tehnici, protocoale',
        description: 'Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
        status: false
      },
      {
        name: 'Programe pentru ingrijirea tenului sensibil, indicatii, tehnici, protocoale.',
        description: 'Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri Anatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uriAnatomia și fiziologia pielii și părului. Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
        status: false
      }
    ]
  }),
  mounted(){
    // this.cards = this.content;
    // this.cards.forEach(obj => {
    //   obj.status = false;
    // });
    // console.log(this.cards);
  }
}
</script>

<style lang="css">
  @media (max-width: 1200px) and (min-width: 430px) {
    .course__content {
      padding: 100px 50px !important;
    }
  }
</style>