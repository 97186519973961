<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;  display: block; shape-rendering: auto;" width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <g transform="translate(82,50)">
  <g transform="rotate(0)">
  <circle cx="0" cy="0" r="4" :fill="color" fill-opacity="1">
    <animateTransform attributeName="transform" type="scale" begin="-0.6944444444444444s" values="2.6199999999999997 1;1 1" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite"></animateTransform>
    <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite" values="1;0" begin="-0.6944444444444444s"></animate>
  </circle>
  </g>
  </g><g transform="translate(74.51342217980729,70.56920350996926)">
  <g transform="rotate(40)">
  <circle cx="0" cy="0" r="4" :fill="color" fill-opacity="0.8888888888888888">
    <animateTransform attributeName="transform" type="scale" begin="-0.607638888888889s" values="2.6199999999999997 1;1 1" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite"></animateTransform>
    <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite" values="1;0" begin="-0.607638888888889s"></animate>
  </circle>
  </g>
  </g><g transform="translate(55.55674168534178,81.51384809639066)">
  <g transform="rotate(80)">
  <circle cx="0" cy="0" r="4" :fill="color" fill-opacity="0.7777777777777778">
    <animateTransform attributeName="transform" type="scale" begin="-0.5208333333333334s" values="2.6199999999999997 1;1 1" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite"></animateTransform>
    <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite" values="1;0" begin="-0.5208333333333334s"></animate>
  </circle>
  </g>
  </g><g transform="translate(34.00000000000001,77.71281292110204)">
  <g transform="rotate(119.99999999999999)">
  <circle cx="0" cy="0" r="4" :fill="color" fill-opacity="0.6666666666666666">
    <animateTransform attributeName="transform" type="scale" begin="-0.4340277777777778s" values="2.6199999999999997 1;1 1" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite"></animateTransform>
    <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite" values="1;0" begin="-0.4340277777777778s"></animate>
  </circle>
  </g>
  </g><g transform="translate(19.929836134850934,60.944644586421404)">
  <g transform="rotate(160)">
  <circle cx="0" cy="0" r="4" :fill="color" fill-opacity="0.5555555555555556">
    <animateTransform attributeName="transform" type="scale" begin="-0.3472222222222222s" values="2.6199999999999997 1;1 1" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite"></animateTransform>
    <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite" values="1;0" begin="-0.3472222222222222s"></animate>
  </circle>
  </g>
  </g><g transform="translate(19.92983613485093,39.0553554135786)">
  <g transform="rotate(200)">
  <circle cx="0" cy="0" r="4" :fill="color" fill-opacity="0.4444444444444444">
    <animateTransform attributeName="transform" type="scale" begin="-0.2604166666666667s" values="2.6199999999999997 1;1 1" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite"></animateTransform>
    <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite" values="1;0" begin="-0.2604166666666667s"></animate>
  </circle>
  </g>
  </g><g transform="translate(33.999999999999986,22.28718707889797)">
  <g transform="rotate(239.99999999999997)">
  <circle cx="0" cy="0" r="4" :fill="color" fill-opacity="0.3333333333333333">
    <animateTransform attributeName="transform" type="scale" begin="-0.1736111111111111s" values="2.6199999999999997 1;1 1" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite"></animateTransform>
    <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite" values="1;0" begin="-0.1736111111111111s"></animate>
  </circle>
  </g>
  </g><g transform="translate(55.55674168534176,18.48615190360934)">
  <g transform="rotate(280)">
  <circle cx="0" cy="0" r="4" :fill="color" fill-opacity="0.2222222222222222">
    <animateTransform attributeName="transform" type="scale" begin="-0.08680555555555555s" values="2.6199999999999997 1;1 1" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite"></animateTransform>
    <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite" values="1;0" begin="-0.08680555555555555s"></animate>
  </circle>
  </g>
  </g><g transform="translate(74.51342217980729,29.430796490030733)">
  <g transform="rotate(320)">
  <circle cx="0" cy="0" r="4" :fill="color" fill-opacity="0.1111111111111111">
    <animateTransform attributeName="transform" type="scale" begin="0s" values="2.6199999999999997 1;1 1" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite"></animateTransform>
    <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.78125s" repeatCount="indefinite" values="1;0" begin="0s"></animate>
  </circle>
  </g>
  </g>
</svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#eb236b"
    }
  }
}
</script>